import React, { useEffect } from "react";
import { Link } from "gatsby";
import { menuHandler } from "./menuHandler";
import { itemClick } from "./assets/itemClick";

const Sider = (props) => {
  useEffect(() => {
    menuHandler();
  }, []);

  //Mainly used to make the correct HREF to pages.
  const isParentPage = (currentLink) => {
    if (
      props.parentPage.slug.current === props?.slug?.current ||
      props?.link ||
      props?.route
    ) {
      return `/${currentLink}`;
    } else {
      return `/${props.parentPage.slug.current}/${currentLink}`;
    }
  };

  //Setting custom ID on every menu item.
  {
    var counter = 0;
  }
  const getId = (title) => {
    counter++;
    var pos = title.indexOf(" ");
    if (pos != -1) {
      var s = title.substring(0, pos);
      return "menuId" + s + counter;
    }
    return "menuId" + title + counter;
  };

  //Level 1
  const LevelOne = () => {
    {
      counter++;
    }
    const mapper =
      props?.props?.items &&
      props.props.items.map((item1) => (
        <li
          className="menuLevel1 menuItem menuitemHidden"
          id={getId(item1.title)}
        >
          <Link
            to={isParentPage(item1.landingPageRoute.slug.current)}
            className="linkBox text"
            activeClassName="active1"
            partiallyActive={true}
          >
            {item1.title}  
            <svg xmlns="http://www.w3.org/2000/svg" width="19.318" height="14.359" viewBox="0 0 19.318 14.359">
              <g id="Component_2_67" data-name="Component 2 – 67" transform="translate(0 0.68)">
                <path id="Path_11554" data-name="Path 11554" d="M0,6.023,6.5,0,13,6.023" transform="translate(17.954) rotate(90)" fill="none" stroke="#405e8d" stroke-width="2"/>
                <path id="Path_11555" data-name="Path 11555" d="M0,0V17.954" transform="translate(17.954 6.5) rotate(90)" fill="none" stroke="#405e8d" stroke-width="2"/>
              </g>
            </svg>
          </Link>
          
          {item1?.subLink?.length > 0 && (
            <ul>
              {" "}
              <LevelTwo item2={item1.subLink} />
            </ul>
          )}
        </li>
      ));
    return mapper;
  };

  //Level 2
  const LevelTwo = ({ item2 }) => {
    const mapper = item2.map((invidivualItem2) => {
      return (
        <li
          className="menuLevel2 menuItem menuitemHidden"
          id={getId(invidivualItem2.title)}
        >
          <Link
            to={isParentPage(invidivualItem2.landingPageRoute.slug.current)}
            className="linkBox"
            activeClassName="active2"
            partiallyActive={true}
          >
            {invidivualItem2.title}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19.318"
              height="14.359"
              viewBox="0 0 19.318 14.359"
            >
              <g
                id="Component_2_67"
                data-name="Component 2 – 67"
                transform="translate(0 0.68)"
              >
                <path
                  id="Path_11554"
                  data-name="Path 11554"
                  d="M0,6.023,6.5,0,13,6.023"
                  transform="translate(17.954) rotate(90)"
                  fill="none"
                  stroke="#405e8d"
                  stroke-width="2"
                />
                <path
                  id="Path_11555"
                  data-name="Path 11555"
                  d="M0,0V17.954"
                  transform="translate(17.954 6.5) rotate(90)"
                  fill="none"
                  stroke="#405e8d"
                  stroke-width="2"
                />
              </g>
            </svg>
          </Link>
          {invidivualItem2?.subLink?.length > 0 && (
            <ul className="menuList">
              {" "}
              <LevelThree item3={invidivualItem2.subLink} />
            </ul>
          )}
        </li>
      );
    });
    return mapper;
  };

  //Level 3
  const LevelThree = ({ item3 }) => {
    if (item3) {
      const mapper = item3.map((invidivualItem3) => {
        return (
          <li
            className="menuLevel3 menuItem menuitemHidden"
            id={getId(invidivualItem3.title)}
          >
            <Link
              to={isParentPage(invidivualItem3.landingPageRoute.slug.current)}
              className="linkBox"
              activeClassName="active3"
              partiallyActive={true}
            >
              {invidivualItem3.title}
            </Link>
            {invidivualItem3.subLink && invidivualItem3.subLink.length > 0 && (
              <ul>
                {" "}
                <LevelThree item3={invidivualItem3.sublink} />
              </ul>
            )}
          </li>
        );
      });

      return mapper;
    } else {
      return null;
    }
  };

  return (
    <ul className="menuList">
      <LevelOne />
    </ul>
  );
};
export default Sider;
